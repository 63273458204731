// en, de, fr, pt
export default {
    en: {
        moreExperienceMoreSuccess: 'More Experience More Success',
        Dashboard: 'Dashboard',
        Vendor: 'Vendor',
        Vendors: 'Vendors',
        Order: 'Order',
        Orders: 'Orders',
        User: 'User',
        Users: 'Users',
        LoginTitle: 'Login',
        LoginText: 'Welcome back, please login to your account.',
        Username: 'User name',
        Password: 'Password',
        RememberMe: "Remember Me",
        ForGetPwd: "Forget Password",
        FirstName: "First Name",
        LastName: 'Last Name',
        Email: "Email",
        Status: "Status",
        Phone: "Phone",
        AddNew: 'Add New',
        Edit: 'Edit',
        BasicInfo: 'Basic Info',
        ContactingDetails: 'Contacting details',
        BankingDetails: 'Banking details',
        RestaurantName: 'Restaurant Name',
        RestaurantAddress: 'Restaurant Address',
        Country: 'Country',
        BankName: 'Bank Name',
        BankBranch: 'Bank Brach',
        AccountName: 'Account Name',
        AccountNumber: 'Account Number',
        Update: 'UPDATE',
        Add: 'Add',
        Item: 'Item',
        ItemsCount: 'Item Count',
        Submit: 'Submit',
        Cancel: 'Cancel',
        Available: 'Available',
        Success: 'Success',
        Fail: 'Fail',
        Error: 'Error',
        Items: 'Items',
        Amount: 'Amount',
        AmountTotal: 'Total',
        OrderType: 'Order Type',
        PaymentType: 'Paymen Type',
        Date: 'Date',
        Action: 'Action',
        Categories: 'Categories',
        LastOrder: 'Last 10 Orders',
        ListOfItem: 'List of Items',
        Count: 'Count',
        SubTotal: 'Sub Total',
        Total: 'Total',
        PaidStatus: 'Paid Status',
        Privilege: 'Privilege',
        NameAr: 'Arabic Name',
        NameEn: 'English Name',
        Image: 'Image',
        Info: 'Info',
        RemoveImage: 'Remove Image',
        UpdateImage: 'Update Image',
        Category: 'Category',
        DeleteConfirMessage: 'Do you mant to delete this item?',
        confirm: 'Confirm',
        Yes: 'Yes',
        No: 'No',
        LastThirteDay: 'Last 30 days',
        AllOrders: 'All Orders',
        Vat: 'Vat',
        Accept: 'Accept',
        Commission: 'Commission',
        Commissions: 'Commissions',
        Fees: 'Fees',
        EditFee: 'Edit Fee',
        LastCollectionDate: 'LAST COLLECTED COMMISSIONS',
        TotalNewOrder: 'TOTAL NEW ORDERS',
        ToBeCollectionNow: 'TO BE COLLECTED NOW',
        Collect: 'Collect',
        OrderStatus: 'Orde Status',
        RecoverYourPassword: 'Recover Your Password',
        ForgetMessage: 'Please enter your email address and well send you instructions on how to reset your password.',
        BackToLogin: 'Back To Login',
        RecoverPassword: 'Recover Password',
        NotAuthorized: 'You are not authorized!',
        BackToHome: 'Back to Home',
        CountNewOrder: 'TOTAL NEW ORDERS',
        Advertise: 'Advertise',
        Advertises: 'Advertises',
        Name: 'Name',
        Video: 'Video',
        UpdateVideo: 'Update Video',
        AddVideo: 'Add Video',
        EditPassword: 'Edit Password',
        OldPassword: 'Old Password',
        NewPassword: 'New Password',
        ConfirmPassword: ' Confirm Password',
        Logout: 'Logout',
        Price: 'Price',
        Size: 'Size',
        ShowVideo: 'Show Video',
        Suppliers: 'Suppliers',
        FullName: 'Full Name',
        City: 'City',
        ShopName: 'Shop Name',
        UserName: 'User Name',
        Identity: 'Identity',
        ContactPhoneNumber: 'Contact Phone Number',
        Information: 'Information',
        Address: 'Address',
        DisplayContactPhone: 'Display Contact Phone',
        Home: 'Home',
        Language: 'عربي',
        Services: 'Services',
        Portfolio: 'Portfolio',
        Pricing: 'Pricing',
        About: 'About',
        Teams: 'Teams',
        Contact: 'Contact',
        ContactUs: 'Contact Us',
        LeaveMessage: 'Leave A Message.',
        MessageText: 'We attribute our success to our satisfied consumers who have repeatedly used and recommended our services with confidence. ',
        AddressDescription: 'Arkaweet, Block 68, Build. 415.',
        BoxNumber: 'P.O>Box 15013 Khartoum-Sudan',
        SendMessage: 'Send Message',
        Subject: 'Subject',
        Loading: 'Loading ...',
        SendSuccess: 'Email Send Successfly',
        SendFaild: 'Mail has not been sent',
        OurClinet: 'Our Clinets',
        Team: 'Team',
        Commitment: 'Commitment',
        Solutions: 'Solutions',
        Support: 'Support',

        Works: 'Works',
        Contents: {
            AboutUs: 'About Us',
            AboutUsDetails: "JM Electric was founded to provide quality electrical solutions that satisfy our clients' needs and requirements. We can design products to your exact specifications. For this reason, we aim to produce the tools and solutions that easily fit and integrate inside the projects requirement.",
            AboutUsDetails2: "Moreover, all projects, from commercial to residential to industrial, are addressed equally. Expert project managers, qualified electricians, and office-based support staff are allocated to each project in order to ensure your project is a success.",
            Mission: 'Mission',
            MissionDetails: "To establish long-term relationships with our clients by providing engineering value, innovative solutions, high-quality goods, and technical support.",
            Vision: 'Vision',
            VisionDetails: "We aim to be the top solution provider for Electromechanics goods and services by submitting excellence in all of our activities.",
            OurValues: 'Our Values',
            Values: 'Values',
            OurValuesDetails: {
                Integrity: 'Integrity.',
                Respect: 'Respect.',
                ConstantImprovement: 'Constant Improvement.',
                CompleteAccountability: 'Complete Accountability.',
                CommitmentToBusinessEthics: 'Commitment to Business Ethics.'
            },
            OurServices: 'Our Services',
            OurServicesDetails: {
                LowVoltage: 'Low Voltage',
                LowVoltageDetails: {
                    DistributionControlPanels: 'Distribution & Control Panels.',
                    LightingWD: 'Lighting, WD.',
                    LowCurrent: 'Low Current (Fire alarm, Security, CCTV & Data).',
                    CableTermination: 'Cable Termination & HiPot Testing.'
                },
                Energy: 'Energy',
                EnergyDetails: {
                    UPSAVR: 'UPS & AVR',
                    Automation: 'Automation',
                    Genset: 'Genset',
                    Solar: 'Solar'
                },
                MediumVoltage: 'Medium Voltage',
                MediumVoltageDetails: {
                    Substation: 'Substation.',
                    Gensets: 'Gensets.',
                    Switchgears: 'Switchgears.',
                    CableTermination: 'Cable Termination & HiPot Testing.'
                },
            },
            WhyChoseUs: 'Why we should be your first choice?',
            WhyChoseUsDetails: {
                BestQualityTechnicalSupport: 'We guarantee top of the line quality & technical support',
                AffordablePricesStockFastDelivery: 'We offer Attractive prices.A sizable stock.Prompt delivery',
                ProvideCustomizedSolutionsProducts: 'We provide customized solutions & Products',
                HighlyCommittedWithBusinessEthics: 'We are highly committed with business ethics',
                OurTeamIsVeryFriendlySupportive: 'We have a friendly & supportive team'
            },
            Journeys: 'Journey',
            OurJourneys: 'Our Journey',
            OurJourney: 'Our Journey towards a decade of success',
            Journey: {
                About2015: 'launching jM Electric and ensuring that we leave a lasting impression in the industry',
                About2016: 'Expansion into the Middle East and North Africa (MENA) region. Specifically, Egypt and Dubai.',
                About2017: 'It was this year where our lighting department, saw the light and we entered the world of lighting fixtures and solutions. ',
                About2018: 'Marked the beginning of collaboration with Turkish entities during Istanbul Light Expo, who played a major role in our expansions thus far.',
                About2019: 'Due to the growing demand, we engaged in the generation, distribution, and sale of UPS, as well as related products and services.',
                About2020: 'Apart from launching our own JM final distribution boards (FDB) in 2020, we spent the year expanding our portfolio and undertaking some of our largest projects to date.',
                About2021: 'A year to look inwards, we used the time this year gave us to really refine ourselves internally. Everything from our offices to our operations has been reorganized to operate more efficiently.',
                AboutLast: 'Lastly, but certainly not least, we left our mark on the digital world in order to keep up with the IoT.'
            }



        },
        Validation: {
            MessageRequired: 'Message required',
            EmailRequired: 'Email required'
        }
    },


    ar: {
        moreExperienceMoreSuccess: '  خبرتنا، نجاحنا',
        Dashboard: 'الداشبورت',
        Vendor: 'العملاء',
        Vendors: 'العملاء',
        Order: 'الطلبات',
        Orders: 'الطلبات',
        User: 'الذبائن',
        Users: 'الزبائن',
        Admins: 'المستخدمين',
        LoginTitle: 'تسجيل الدخول',
        LoginText: 'الرجاء ادخال بيانات الدخول',
        Username: 'اسم المستخدم',
        Password: 'كلمة المرور',
        RememberMe: "تذكرني",
        ForGetPwd: "استعادة كلمة المرور",
        FirstName: "الاسم الاول",
        LastName: 'اسم العائلة',
        Email: "البريد الالكتروني",
        Status: "الحالة",
        Phone: "رقم الهاتف",
        AddNew: 'اضافة',
        Edit: 'تعديل',
        BasicInfo: 'البيانات الاساسيه',
        ContactingDetails: 'بيانات التواصل',
        BankingDetails: 'بيانات الحساب',
        RestaurantName: 'اسم المطعم',
        RestaurantAddress: 'عنوان المطعم',
        Country: 'المحافظه',
        State: 'الدولة',
        BankName: 'اسم البنك',
        BankBranch: 'فرع البنك',
        AccountName: 'اسم الحساب',
        AccountNumber: 'رقم الحساب',
        Update: 'تعديل',
        Add: 'اضافة',
        Item: 'عنصر',
        Submit: 'حفظ',
        Available: 'متاح',
        Success: 'نجاح',
        Fail: 'فشل',
        Error: 'خطا',
        Items: 'الاصناف',
        ItemsCount: 'عدد الاصناف',
        Amount: 'السعر',
        AmountTotal: 'اجمالي السعر',
        OrderType: 'نوع الطلب',
        PaymentType: 'طريقه الدفع',
        Date: 'التاريخ',
        Action: 'العمليات',
        Categories: 'الاقسام',
        LastOrder: 'اخر عشره طلبات',
        ListOfItem: 'الطلبات',
        Count: 'العدد',
        SubTotal: 'المجموع',
        Total: 'الاجمالي',
        PaidStatus: 'حالة الدفع',
        Privilege: 'الصلاحيه',
        NameAr: 'الاسم العربي',
        NameEn: 'الاسم الانجليزي',
        Image: 'الصورة',
        Info: 'البيانات',
        RemoveImage: 'حذف الصورة',
        UpdateImage: 'اختيار صورة',
        Category: 'التصنيف',
        DeleteConfirMessage: 'هل تريد حذف هذا العنصر ؟',
        confirm: 'تاكيد',
        Yes: 'نعم',
        No: 'لا',
        LastThirteDay: 'اخر ٣٠ يوم',
        AllOrders: 'كل الطلبات',
        Vat: 'القيمة المضافة',
        Accept: 'موافقه',
        Cancel: 'الغاء',
        Commission: 'العمولات',
        Commissions: 'العمولات',
        Fees: 'رسوم الخدمه',
        EditFee: 'تعديل رسوم الخدمه',
        LastCollectionDate: 'اخر تاريخ للجمع',
        TotalNewOrder: 'اجمالي سعر الطلبات',
        ToBeCollectionNow: 'الرسوم المطلوبه',
        Collect: 'جمع',
        OrderStatus: 'حالة الطلب',
        RecoverYourPassword: 'استعادة كلمة المرور',
        ForgetMessage: 'الرجاء ادخال بريدك الالكتروني لارسال كلمة المرور الجديده فيه ',
        BackToLogin: 'الرجول الي شاشة الدخول',
        RecoverPassword: 'استعاده كلمة المرور',
        NotAuthorized: 'ليس لديك صلاحيه!',
        BackToHome: 'رجوع الي الرئيسيه',
        CountNewOrder: 'اجمالي عدد الطلبات',
        Advertise: 'الاعلانات',
        Advertises: 'الاعلانات',
        Name: 'الاسم',
        Video: 'الفيديو',
        UpdateVideo: 'رفع فيديو',
        AddVideo: 'اضافة فيديو',
        EditPassword: 'تعديل كلمة المرور',
        OldPassword: 'كلمة المرور القديمه',
        NewPassword: 'كلمة المرور الجديد',
        ConfirmPassword: ' تاكيد كلمة المرور',
        Logout: 'تسجيل الخروج',
        Price: 'السعر',
        Size: 'الحجم',
        ShowVideo: 'معاينة الفيديو',
        Suppliers: 'العملاء',
        Supplier: 'العميل',
        FullName: 'الاسم',
        City: 'المدينه',
        ShopName: 'اسم المتجر',
        UserName: 'اسم المستخدم',
        Identity: 'الهويه',
        ContactPhoneNumber: 'رقم التواصل',
        Information: 'معلومات التواصل',
        Address: 'العنوان',
        DisplayContactPhone: ' رقم التواصل',
        DisplayBankingInfo: ' بيانات البنك',
        DisplayDeliveryOption: ' خيار التوصيل',
        DisplayProducts: ' المنتجات',
        DisplayProductsReview: ' طرق عرض المنتجات',
        DisplayProductsViews: '  عرض المنتجات',
        DisplayOtherBranches: 'عرض الفروع',
        DisplayProductsPrices: 'سعر المنتجات',
        DisplaySLider: 'عرض الاعلانات',
        DisplayNeews: 'عرض شريط الاخبار',
        Products: 'المنتجات',
        Shipping: 'ادارة الشحن',
        Attribute: 'الخصائص',
        Chat: 'الدردشه',
        Complaint: 'الشكاوي',
        Message: 'الرساله',
        News: 'الاخبار',
        Home: 'الرئيسيه',
        Language: 'English',
        Services: 'خدماتنا',
        Portfolio: 'اعمالنا',
        Pricing: 'الاسعار',
        About: 'من نحن',
        Teams: 'فريق العمل',
        Contact: 'اتصل بنا',
        ContactUs: 'تواصل معنا',
        LeaveMessage: 'اترك رساله',
        MessageText: 'نجاحنا ينبت من التعاون المتواصل الذي نستخدمه مع عملائنا الذين وثقوا بنا وبخدماتنا واوصوا بها على أساس منتظم.',
        AddressDescription: 'اركويت مربع 68 بناء. 415.',
        BoxNumber: 'ص.ب 15013 الخرطوم - السودان',
        SendMessage: 'أرسل رسالة',
        Subject: 'العنوان',
        Loading: 'جاري التحميل ....',
        SendSuccess: 'تم ارسال البريد الالكتروني',
        SendFaild: 'لم يتم ارسال البريد',
        OurClinet: 'عملائنا',
        Team: 'الفريق',
        Commitment: 'الالتزام',
        Solutions: 'الحلول',
        Support: 'الدعم',
        Works: 'اعمالنا',
        Value: 'قيمتنا',
        Contents: {
            AboutUs: 'من نحن',
            AboutUsDetails: "تم تأسيس jM Electric لتوفير خدمات كهربائية تلبي جميع احتياجات العملاء المتعددة. وإننا تمكنا من تحقيق ذلك عبر أكثر من ٧ اعوام فضلاً لطريقة عملنا والتي ننظر فيها على كل مشروع ومواصفاته على حدى، وبعدها نقوم بابتكار حلول الإضاءة أو التصميم التي يمكننا دمجها بسلاسة. ",
            AboutUsDetails2: "بالإضافة لذلك، سر نجاح مشاريعنا الأول هو فريقنا المتناغم. كل المشاريع يتولاها مديرو مشاريع ذوي الخبرة وكهربائيون مؤهلين وموظفي دعم في مكتبنا المحلي لضمان نجاح مشروعك بأحسن طريقة ممكنة.",
            Mission: 'المهمه',
            MissionDetails: "تأكيد علاقات طويلة الأمد مع عملائنا من خلال القيمة الهندسية المضافة والأفكار المبدعة والدعم الفني ما قبل وبعد البيع.",
            Vision: 'الرؤيه',
            VisionDetails: "طموحنا هو أن نكون الخيار الأول لجميع الخدمات الكهرو ميكانيكية في السودان وذلك عبر إبراز جودة غير مسبقة في كل ما نقوم به. ",
            OurValues: 'قيمنا',
            Values: 'قيمنا',

            OurValuesDetails: {
                Integrity: 'النزاهة.',
                Respect: 'الاحترام.',
                ConstantImprovement: 'التحسين المستمر.',
                CompleteAccountability: 'المسؤولية التامة.',
                CommitmentToBusinessEthics: 'الالتزام بأخلاقيات العمل.'
            },
            OurServices: 'خدماتنا',
            OurServicesDetails: {
                LowVoltage: 'ضغط كهربائي منخفض',
                LowVoltageDetails: {
                    DistributionControlPanels: 'لوحات التوزيع والتحكم.',
                    LightingWD: 'الإضاءة وملحقاتها',
                    LowCurrent: 'تيار منخفض (إنذار الحريق، الأمن ، الدوائر التلفزيونية المغلقة والبيانات)',
                    CableTermination: 'إنهاء الكابلات واختبار HiPot.'
                },
                Energy: 'المولدات - الطاقه الشمسيه',
                EnergyDetails: {
                    UPSAVR: 'منظمات الجهد وحافظات الطاقه',
                    Automation: 'التشغيل الآلي',
                    Genset: 'المولدات',
                    Solar: 'الطاقه الشمسيه'
                },
                MediumVoltage: 'ضغط كهربائي متوسط (11/33 ك)',
                MediumVoltageDetails: {
                    Substation: 'محطات فرعية.',
                    Gensets: 'المولدات بجميع أنواعها.',
                    Switchgears: 'مفاتيح التشغيل',
                    CableTermination: 'إنهاء الكابلات وعمل جميع الاختبارات'
                },
            },
            WhyChoseUs: 'لما jM Electric هي خيارك الأول',
            WhyChoseUsDetails: {
                BestQualityTechnicalSupport: ' لديناأفضل جودة ودعم فني',
                AffordablePricesStockFastDelivery: 'لديناأسعار في متناول الجميع مخزون كبير من المنتجات سرعة في التسليم',
                ProvideCustomizedSolutionsProducts: 'لدينا حلول ومنتجات مخصصة',
                HighlyCommittedWithBusinessEthics: 'لديناأخلاقيات نزيهة في العمل',
                OurTeamIsVeryFriendlySupportive: 'لدينا فريق متعاون ومستعد لخدمتك بأحسن صورة ممكنة.'
            },
            OurJourneys: 'رحلتنا',
            Journeys: 'رحلتنا',
            OurJourney: 'رحلتنا نحو عقد من النجاح',
            Journey: {
                About2015: 'انطلقت شركة jM Electric بهدف أن تحدث تغيير جذري في مجالها',
                About2016: 'التوسعة للشرق الأوسط. وبالتحديد تجاه مصر ودبي.',
                About2017: 'هذه هي السنة التي أنشأنا فيها قسم الإضاءة.',
                About2018: 'كانت هذه السنة علامة لبداية شراكاتنا المتعددة مع الكثير من الهيئات التركية',
                About2019: 'فضلاً للطلب المتزايد أنشأنا قسم UPS  والذي يهتم بكل من تحويل وتخزين الطاقة البديلة وجميع خدماتها مثل التركيب والاستشارات',
                About2020: 'بالإضافة لإطلاق لوحة التحكم الخاصة بنا والذي تلقت نجاح واسع في السوق. لقد أمضينا العام في توسيع محفظتنا وتنفيذ بعض أكبر مشاريعنا حتى الآن.',
                About2021: 'استخدمنا هذا العام لتحسين أنفسنا داخليًا. تمت إعادة تنظيم كل شيء من مكاتبنا إلى عملياتنا للعمل بكفاءة أكبر..',
                AboutLast: 'أخيرًا ،     وليس آخراً ، تركنا بصمتنا على العالم الرقمي من أجل مواكبة إنترنت الأشياء'
            }



        },
        Validation: {
            MessageRequired: 'الرسالة مطلوبه',
            EmailRequired: 'البريد الالكتروني مطلوب'
        }
    },

}