<template>
    <section id="home" class="slider_area header">

        <div id="carouselThree" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
                <li data-target="#carouselThree" data-slide-to="0" class="active"></li>
                <li data-target="#carouselThree" data-slide-to="1"></li>
                <li data-target="#carouselThree" data-slide-to="2"></li>
            </ol>

            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img class="image-slider" type="image/webp" src="assets/images/slider/alwaha-reception.webp" alt="alwaha-reception">
<!--                    <v-lazy-image src="assets/images/slider/alwaha-reception.webp" alt="alwaha-reception" style="width:100%;height: 100%"/>-->
                </div>
                <!-- carousel-item -->

                <div class="carousel-item">
<!--                    <v-lazy-image src="assets/images/slider/faisal-islamic-bank.webp" alt="faisal-islamic-bank"  style="width:100%;height: 100%"/>-->
                     <img type="image/webp"  class="image-slider" src="assets/images/slider/faisal-islamic-bank.webp" alt="faisal-islamic-bank">
                </div>
                <!-- carousel-item -->

                <div class="carousel-item">
<!--                    <v-lazy-image src="assets/images/slider/villa.webp" alt="villa" style="width:100%;height: 100%"/>-->
                     <img type="image/webp" class="image-slider" src="assets/images/slider/villa.webp" alt="villa">
                </div>
                <!-- carousel-item -->
            </div>

            <a class="carousel-control-prev" href="#carouselThree" role="button" data-slide="prev">
                <i class="lni lni-arrow-left"></i>
            </a>
            <a class="carousel-control-next" href="#carouselThree" role="button" data-slide="next">
                <i class="lni lni-arrow-right"></i>
            </a>
        </div>
        
        <div class="caption">
            <div class="custom-container">
                <div class="text">
                    <h1 style="display: flex;" dir="ltr">
                        <div style="color:#208e87;">JM </div> Electric
                    </h1>
                    <h4>
                        {{ $t('moreExperienceMoreSuccess')}}                        
                    </h4>

                </div>
            </div>
        </div>
      
    </section>
</template>


<script>
// import VLazyImage from "v-lazy-image/v2";

export default {
  components: {
    // VLazyImage
  }
};
</script>

<style >
    .slider-image{
        height: 100vh !important;
        width: 100% !important;
    }

    .header .slide .carousel-inner .carousel-item .image-slider {
        height: 100vh !important;
        width: 100% !important;
    }

    .header .slide .carousel-inner .carousel-item img {
        height: 100% !important;
        width: 100% !important;
        object-fit: cover;
    }



    .header .caption {
        position: absolute;
        top: 0;
        z-index: 2;
        width: 100%;
    }

    .header .caption .text {
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        flex-direction: column;
    }

    .header .caption .text span {
        color: var(--white);
        background-color: var(--primary);
        padding: 5px 10px;
        border-radius: 30px;
        font-size: 0.75rem;
        font-weight: 700;
    }

    .header .caption .text h1 {
        font-weight: 700;
        color: var(--white);
        /* width: 100%; */
        text-align: right;
        margin-top: 10px;
        line-height: 62px;
        font-size: 5em;
        padding-bottom: 25px;
    }

    .header .caption .text h3 {
        color: var(--white);
        /* width: 50%; */
        text-align: right;
        line-height: 32px;
    }



    .header .caption .text .tags {
        display: block;
        margin-top: 1em;
    }

    .header .caption .text .tags span.tag {
        background-color: transparent;
        border-radius: 4px;
        border: 1px solid var(--white);
        padding: 5px 10px;
        font-weight: 600;
        display: inline-block;
        margin-right: 4px;
    }

    @media(max-width :700px) {

        .header .slide .carousel-inner .carousel-item img {
            height: 100vh !important;
            width: 100% !important;
            object-fit: cover;
        }

        .header .caption .text {
            position: relative;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            flex-direction: column;
        }

        .header .caption .text h1 {
            font-weight: 700;
            color: var(--white);
            /* width: 100%; */
            text-align: center;
            margin-top: 10px;
            line-height: 62px;
            font-size: 3.5em;
            padding-bottom: 25px;

            align-items: center;
            justify-content: center;
        }

        .header .caption .text h3 {
            color: var(--white);
            /* width: 50%; */
            text-align: right;
            line-height: 32px;
            font-size: 1.5em;
        }
    }



</style>