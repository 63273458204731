import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
// i18n
import i18n from './i18n/i18n'

// swiper
import VueAwesomeSwiper from 'vue-awesome-swiper'
// import style
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper, /* { default options with global component } */ )

// Carousel
import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

// axios
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

// cookies
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

// scrolling
var Scrollactive = require('vue-scrollactive');
Vue.use(Scrollactive);

import VueMatomo from 'vue-matomo';
Vue.config.productionTip = false;
Vue.use(VueMatomo, {
    host: "https://tracking.jmelectric.sd/",
    siteId: 1,
    trackerFileName: 'matomo',
    router: router,
    enableLinkTracking: true,
    requireConsent: false,
    trackInitialView: true,
    disableCookies: false,
    enableHeartBeatTimer: false,
    heartBeatTimerInterval: 15,
    debug: false,
    userId: undefined,
    cookieDomain: undefined,
    domains: undefined,
    preInitActions: []
});

import loader from "vue-ui-preloader";
Vue.use(loader);


Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')