<template>
  <div class="home" style="overflow-x:hidden">
    <!--[if IE]>
    <p class="browserupgrade">You are using an <strong>outdated</strong> browser. Please <a href="https://browsehappy.com/">upgrade your browser</a> to improve your experience and security.</p>
  <![endif]-->

    <!--====== PRELOADER PART START ======-->
    
    <div id="app">
        <!-- <loader  object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" name="circular" disableScrolling="false"></loader> -->
    </div>

    <!-- <div class="preloader">
        <div class="loader">
            <div class="ytp-spinner">
                <div class="ytp-spinner-container">
                    <div class="ytp-spinner-rotator">
                        <div class="ytp-spinner-left">
                            <div class="ytp-spinner-circle"></div>
                        </div>
                        <div class="ytp-spinner-right">
                            <div class="ytp-spinner-circle"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <!--====== PRELOADER PART ENDS ======-->

    <!--====== NAVBAR TWO PART START ======-->
    <nav-bar></nav-bar>
    
    <!--====== NAVBAR TWO PART ENDS ======-->

    <!--====== SLIDER PART START ======-->

    <Slider></Slider>


    <Testimonials></Testimonials>
    
    <Brand></Brand>

    <!--====== SLIDER PART ENDS ======-->




    <!--====== FEATRES TWO PART START ======-->
    <Services></Services>
    

    <!--====== FEATRES TWO PART ENDS ======-->

    <!--====== PORTFOLIO PART START ======-->

    <Works></Works>
    <Clients></Clients>

    <!--====== PORTFOLIO PART ENDS ======-->




    
    <!--====== WHy Chose us START ======-->

    <why-chose-us></why-chose-us>
    

    <!--====== Why Chose Us ENDS ======-->


    

    
    <!--====== ABOUT PART START ======-->

    <About></About>

    <!--====== ABOUT PART ENDS ======-->
  

     
    

    <!--====== PRINICNG START ======-->

    <Journey></Journey>

    <!--====== PRINICNG ENDS ======-->
    

    <!--====== TESTIMONIAL PART START ======-->
    <!-- <testimonial> </testimonial> -->
    

    <!--====== TESTIMONIAL PART ENDS ======-->

    <!--====== TEAM START ======-->

    <!-- <Team></Team> -->

    <!--====== TEAM  ENDS ======-->

    <!--====== CONTACT PART START ======-->
    <contact-us></contact-us>
    

    <!--====== CONTACT PART ENDS ======-->

    <!--====== FOOTER PART START ======-->

    <Footer></Footer>

    <!--====== FOOTER PART ENDS ======-->

    <!--====== BACK TOP TOP PART START ======-->

    <a href="#"  class="back-to-top"><i style="color: white;" class="lni lni-chevron-up"></i></a>

    <!--====== BACK TOP TOP PART ENDS ======-->

    <!--====== PART START ======-->

    <!--
    <section class="">
        <div class="container">
            <div class="row">
                <div class="col-lg-">
                    
                </div>
            </div>
        </div>
    </section>
-->

    <!--====== PART ENDS ======-->

  </div>
</template>




<script>
// import Layouts
import NavBar from './layouts/navbar'
import Footer from './layouts/footer'

// import Component
// import Services from './components/services'
// import ContactUs from './components/contact'
// import About from './components/about'
// import Works from './components/works'
import Slider from './components/slider'
// import WhyChoseUs from './components/whyChoseUs'
// import Brand from './components/brand'
// import Journey from './components/Journey'
// import Clients from './components/clients'
import {defineAsyncComponent} from "vue";

// import Testimonials from './components/testimonial'

// const Testimonials = defineAsyncComponent(() =>
//   import('./components/testimonial')
// );

export default {
    components:{
        NavBar,
        Footer,
        Services: defineAsyncComponent(() => import('./components/services')),
        ContactUs: defineAsyncComponent(() => import('./components/contact')),
        About: defineAsyncComponent(() => import('./components/about')),
        Works : defineAsyncComponent(() => import('./components/works')),
        Slider,
        WhyChoseUs: defineAsyncComponent(() => import('./components/whyChoseUs')),
        Brand: defineAsyncComponent(() => import('./components/brand')),
        Journey: defineAsyncComponent(() => import('./components/Journey')),
        Testimonials: defineAsyncComponent(() => import('./components/testimonial')),
        Clients: defineAsyncComponent(() => import('./components/clients')),
        
    }
}
</script>




