<template>
    <section class="navbar-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <nav class="navbar navbar-expand-lg">

                        <a class="navbar-brand" href="#">
                            <embed class="logo-green" src="assets/images/logo.svg" alt="Logo">
                            <embed class="logo-white" src="assets/images/whiteLogo.svg" alt="Logo">
                        </a>

                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTwo" aria-controls="navbarTwo" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="toggler-icon"></span>
                            <span class="toggler-icon"></span>
                            <span class="toggler-icon"></span>
                        </button>

                        <div class="collapse navbar-collapse sub-menu-bar" id="navbarTwo">
                            <ul class="navbar-nav m-auto">
                                <li class="nav-item active"><a class="page-scroll" href="#home">{{ $t('Home') }}</a></li>
                                <li class="nav-item"><a class="page-scroll" href="#about">{{ $t('About')}}</a></li>
                                <li class="nav-item"><a class="page-scroll" href="#services">{{ $t('Services')}}</a></li>
                                <li class="nav-item"><a class="page-scroll" href="#portfolio">{{ $t('Portfolio')}}</a></li>
                                <li class="nav-item"><a class="page-scroll" href="#OurValues">{{ $t('Contents.Values')}}</a></li>
                                <li class="nav-item"><a class="page-scroll" href="#OurJourneys">{{$t('Contents.Journeys')}}</a></li>
                                <li class="nav-item"><a class="page-scroll" href="#contact">{{ $t('Contact')}}</a></li>
                                <li class="nav-item"><a  @click="updateLocale()" href="#">{{ $t('Language')}}</a></li>
                            </ul>
                        </div>

                        <!-- <div class="navbar-btn d-none d-sm-inline-block">
                            <ul>
                                <li><a class="solid" href="#">Download</a></li>
                            </ul>
                        </div> -->
                    </nav>
                    <!-- navbar -->
                </div>
            </div>
            <!-- row -->
        </div>
        <!-- container -->
    </section>
</template>

<script>
export default {
    data() {
      return {
        windowTop: window.top.scrollY
      }
    },
    methods: {
        toggleBodyClass(addRemoveClass, className) {
            const el = document.body;
            if (addRemoveClass === 'addClass') {
            el.classList.add(className);
            } else {
            el.classList.remove(className);
            }
        },
        updateLocale() {
            if(this.$i18n.locale == 'ar'){
                this.toggleBodyClass('removeClass', 'rirht-dir');
                this.$i18n.locale = 'en'
                this.$cookies.set('JmDirection','ltr')
            }else if(this.$i18n.locale == 'en') {
                this.toggleBodyClass('addClass', 'rirht-dir');
                this.$i18n.locale = 'ar'
                this.$cookies.set('JmDirection','rtl')
            }
        },
    },

    // watch: {
    //   windowTop: {
    //     immediate: true,
    //         handler (newVal, oldVal) {
    //         console.log(newVal, oldVal);
    //     },
    //   }
    // },
    mounted() {
        this.$cookies.set('JmDirection','ltr')
    },
}
</script>